import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Priser from "../components/priser"
import { data } from "autoprefixer"

export default function PriserPage({ data }) {
  console.log(data)
  return (
    <Layout>
      <div className="bg-primary/30">
        <Priser data={data.wpPage} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 73 }) {
      content
    }
  }
`

import React from "react"
import Container from "./container"

export default function Priser({ data }) {
  console.log(data)
  const { content } = data
  return (
    <Container backgroundColor="third">
      <div className="max-w-4xl mx-auto ">
        <h1 className="page-header">Priser</h1>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="space-y-4"
        />
      </div>
    </Container>
  )
}
